// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation Upload(
    $file: Upload!,
    $path: String,
    $name: String
  ) {
    upload(
      file: $file,
      path: $path,
      name: $name
    ) {
      id
      name
    }
  }
`;

export default MUTATION;
